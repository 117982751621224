const pages = [
  
  
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'public'
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'blank',
    },
  },
  // {
  //   path: '/sage',
  //   name: 'sage',
  //   component: () => import('@/views/apps/sage/chat/Chat.vue'),
  //   meta: {
  //     title: 'Sage',
  //     layout: 'content',
  //     resource: 'sage',
  //     action: 'read'
  //   },
  // },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/pages/faq/Faq.vue'),
    meta: {
      title: 'FAQ',
      layout: 'content',
      resource: 'public'
    },
  },
]

export default pages
