// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, reactive, toRefs, watch } from '@vue/composition-api'

import {diffInDays} from './filter'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()

  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0)) {
    return true
  }

  return false
}

export const addDays = (dateToUpdate, days) => {
  var date = new Date(dateToUpdate)
  date.setDate(date.getDate() + days)
  return `${date.getFullYear()}-${toDoubleDigits(date.getMonth() + 1)}-${toDoubleDigits(date.getDate())}T00:00:00.000Z`
}

export const getVuetify = () => {
  const ins = getCurrentInstance()?.proxy

  return ins && ins.$vuetify ? ins.$vuetify : null
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy

  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const isEmpty = value => {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

// ——— Get Initial Text from name ——————— //

export const getInitialName = fullName =>
  // eslint-disable-next-line implicit-arrow-linebreak
  fullName
    .split(' ')
    .map(n => n[0])
    .join('')

// ——— Add Alpha To color ——————— //

export const addAlpha = (color, opacity) => {
  const opacityLocal = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

  return color + opacityLocal.toString(16).toUpperCase()
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToBottom = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = scrollEl.scrollHeight
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToTop = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = 0
}

// ————————————————————————————————————
//* ——— Color Manipulations
// ————————————————————————————————————

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const rgbToHex = (r, g, b) => {
  const componentToHex = c => {
    const hex = c.toString(16)

    return hex.length === 1 ? `0${hex}` : hex
  }

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const hexToRgb = (hex, opacity=100) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  /* eslint-disable indent */
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: (opacity / 100)
      }
    : null
  /* eslint-enable */
}

export const stringToHslColor = (str) => {
  const saturation = 55;
  const lightness = 50;
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl('+h+', '+saturation+'%, '+lightness+'%)';
}

export const formatCountryOptions = (results) => {
  let res = [];
  for (let idx in results){
    let option = {
      value:results[idx].id,
      label: results[idx].name,
      sample: null,
      phase: null,
      field_start_date: null,
      field_end_date: null,
      country: results[idx].id,
      research_location: null,
      modules: [],
      shelf_type: null,
      studio_rate_card: null,
      studio_rate_card: []
    }
    res.push(option)
  }
  return res;
}

export const formatOptions = (results) => {
  let res = [];
  for (let idx in results){
    res.push({value:(!!results[idx].id ? results[idx].id : results[idx].azure_ad_id), label:(!!results[idx].name ? results[idx].name : results[idx].display_name)})
  }
  return res;
}

export const formatStatusOptions = (results) => {
  let res = [];
  for (let idx in results){
    res.push({value:results[idx].code, label:results[idx].name})
  }
  return res;
}

export const formatTasks = (results) => {
  let header = 'PRE-FIELD';
  let res = [{header: header}];
  for (let idx in results){
    if (results[idx].bucket === header){
      res.push(results[idx]);
    } else{
      header = results[idx].bucket;
      res.push({header: header})
      res.push(results[idx])
    }
  }
  return res;
}

export const transformRequestOptions = (params) => {
  var str = [];
  for (var p in params)
    if (params.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
    }
  return str.join("&");
}

export const groupBy = (arr, key) => {
  return arr.reduce( (rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {})
}

export const transformProjectObj = (projects) => {
  return projects.map((item) => {
    return flattenProject(item)
  })
}


export const flattenProject = (item) => {
  let fieldCountries = [];
  if (item.countries){
    item.countries.map(country => {
      fieldCountries.push(country.country_detail.name)
    });
  }
  return {
    ...item,
    client: item.client ? item.client.name : '',
    contact: item.contact ? item.contact.name : '',
    office: item.office ? item.office.name : '',
    service_type: item.service_type ? item.service_type.name : '',
    category: item.category ? item.category.name : '',
    po: item.po ? item.po : '',
    b_rule: item.billing_rule ? item.billing_rule.name : '',
    currency: item.currency ? item.currency.name : '',
    budget: item.budget,
    project: item.job_number ? `${item.job_number} - ${item.job_name}` : item.project,
    oop: item.oop,
    billed_by: item.billed_by ? item.billed_by.name : '',
    ready_to_invoice: item.ready_to_invoice,
    invoiced_to_date: item.invoiced_to_date,
    job_status: item.job_status ? item.job_status.name : '',
    fieldCountries: fieldCountries.join(', ')
  }
}

const nextDate = (dayIndex, nbDays=0) => {
  var today = new Date();
  today.setDate(today.getDate() + (dayIndex - 1 - today.getDay() + 7) % 7 + 1 + nbDays);
  return today;
}

export const setDateSchedule = (setDate) => {
  const diffDays = diffInDays('today', setDate)
  const thisWeek = diffInDays('today', nextDate(0))
  const nextWeek = diffInDays('today', nextDate(0, 7))
  if (diffDays < 0) {
    return 'Overdue'
  } else if (diffDays === 0) {
    return 'Today'
  } else if (diffDays > 0 && diffDays <= thisWeek) {
    return 'This Week'
  } else if (diffDays > thisWeek && diffDays <= nextWeek) {
    return 'Next Week'
  } else {
    return 'Later'
  }

}

export const defaultProject = {
    job_name: null,
    deal: null,
    hs_deal: null,
    client: null,
    report_date: null,
    closed_won_date: null,
    budget: null,
    currency: null,
    requester: null,
    billing_rule: null,
    office: null,
    research_method: null,
    brand: null,
    category: null,
    project_type: null,
    business_initiative: null,
    countries:[],
    po: null,
    oop:0,
    ges_bva: null,
    insights: [],
    departments: [],
    service_type: null,
    team: null,
    key_comments: '',
    contact: null,
  }

  export function downloadTableWith (format, cols, colFields, rows, rowFields, rowHeaderSize, values, filename) {
    if (format !== 'tsv' && format !== 'csv') {
      throw Error('Invalid format on downloading, only "tsv" or "csv" can be used.')
    }
    const _filename = (filename || getFilenameByDate(new Date())) + '.' + format
    const delimiter = format === 'tsv' ? '\t' : ','
    formatAndDownloadWithText(cols, colFields, rows, rowFields, rowHeaderSize, values, delimiter, _filename)
  }
  
  function toDoubleDigits (num) {
    return ('0' + num).slice(-2)
  }
  
  function sortCols(col) {
    for (let i=0; i < col.length; i++){
      col.sort((a, b) => (a[i]))
    }
  }
  
  function getFilenameByDate (date) {
    let y = date.getFullYear()
    let m = toDoubleDigits(date.getMonth() + 1)
    let d = toDoubleDigits(date.getDate())
    let h = toDoubleDigits(date.getHours())
    let _m = toDoubleDigits(date.getMinutes())
    let s = toDoubleDigits(date.getSeconds())
    return y + m + d + '-' + h + _m + s
  }
  
  function formatAndDownloadWithText (cols, colFields, rows, rowFields, rowHeaderSize, values, delimiter, filename) {
    let text = `sep=${delimiter}\n`
    let total = 0
    
    colFields.map((columns, colFieldIndex) => {
      for (let i = 0; i < rowHeaderSize; i++) {
        text += delimiter
      }
      // mapping = columns['mapping']
      cols.sort((a, b) => (a[0] > b[0]) ? 1 : (a[0] === b[0]) ? ((a[1] > b[1]) ? 1 : -1) : -1 )
      cols.map((col) => {
        text += 'mapping' in columns ? `${columns.mapping[col[colFieldIndex]]}`: `${col[colFieldIndex]}`
        text += delimiter
      })
      text += 'Total'
      text += '\n'
    })
  
    rows.sort((a, b) => (a[0] > b[0]) ? 1 : (a[0] === b[0]) ? ((a[1] > b[1]) ? 1 : -1) : -1 )
  
    rows.map((row) => {
      let rowTotal = 0;
      text += rowFields.map((_, rowFieldIndex) => row[rowFieldIndex]).join(delimiter)
      text += rowHeaderSize > 0 ? delimiter : ''
      text += cols.map((col) => {
        const key = row.concat(col);
        rowTotal += values.get(key)!== undefined ? values.get(key) : 0
        return values.get(key) !== undefined ? values.get(key) : 0
      }).join(delimiter)
      text += delimiter+rowTotal;
      text += '\n';
    })
  
    // Column Totals
    for (let i = 0; i < rowHeaderSize; i++) {
      text += 'Total'
      text += delimiter
    }
    cols.map((col) => {
      let colTotal = 0;
       rows.map((row) => {
        const key = row.concat(col);
        colTotal += values.get(key)!== undefined ? values.get(key) : 0
       })
       text += colTotal
       text += delimiter
       total += colTotal
    })
    
    text += total
  
    const bom = new Uint8Array([0xEF, 0xBB, 0xBF])
    const blob = new Blob([bom, text], { type: 'text/plain' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
    link.click()
  }


  export const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    duration %= 3600;
    const minutes = Math.floor(duration / 60);
    if (hours === 0) {

      return `${minutes}min`;
    } else if (minutes === 0) {
      return `${hours}h`;
    } else {
      return `${hours}h ${minutes}min`;
    }
  }

  export const deepDiffMapper =  (() => {
    return {
      VALUE_CREATED: 'created',
      VALUE_UPDATED: 'updated',
      VALUE_DELETED: 'deleted',
      VALUE_UNCHANGED: 'unchanged',
      map: function (obj1, obj2, compare=[]){
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw 'Invalid argument. Function given, object expected.';
        }
        if (this.isValue(obj1) || this.isValue(obj2)) {
          if (this.compareValues(obj1, obj2) !== this.VALUE_UNCHANGED) compare.push({label:obj1 === undefined ? obj2 : obj1, type:this.compareValues(obj1, obj2)})
          return {
            type: this.compareValues(obj1, obj2),
            data: obj1 === undefined ? obj2 : obj1
          };
        }
  
        var diff = {};
        for (var key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }
  
          var value2 = undefined;
          if (obj2[key] !== undefined) {
            value2 = obj2[key];
          }
  
          diff[key] = this.map(obj1[key], value2, compare);
        }
        for (var key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue;
          }
  
          diff[key] = this.map(undefined, obj2[key], compare);
        }
  
        return diff;
  
      },
      compareValues: function (value1, value2) {
        if (value1 === value2) {
          return this.VALUE_UNCHANGED;
        }
        if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
          return this.VALUE_UNCHANGED;
        }
        if (value1 === undefined) {
          return this.VALUE_CREATED;
        }
        if (value2 === undefined) {
          return this.VALUE_DELETED;
        }
        return this.VALUE_UPDATED;
      },
      isFunction: function (x) {
        return Object.prototype.toString.call(x) === '[object Function]';
      },
      isArray: function (x) {
        return Object.prototype.toString.call(x) === '[object Array]';
      },
      isDate: function (x) {
        return Object.prototype.toString.call(x) === '[object Date]';
      },
      isObject: function (x) {
        return Object.prototype.toString.call(x) === '[object Object]';
      },
      isValue: function (x) {
        return !this.isObject(x) && !this.isArray(x);
      }
    }
  })()