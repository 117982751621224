import {
  mdiChartTimeline,
  mdiFileDocumentMultipleOutline,
  mdiAccountOutline,
  mdiMicrosoftTeams,
  mdiOfficeBuildingMarkerOutline,
  mdiMapMarkerMultipleOutline
} from '@mdi/js'

const user = JSON.parse(localStorage.getItem('user')) || {}
const email = user.email || 'email@prs-invivo-group.com'
const favorite_teams_group = user.favorite_teams_group || 0
const office = user.office || 1

export default [
  {
    subheader: 'PROJECTS',
    resource: 'public',
    action: 'read'
  },
  {
    title: 'Projects',
    icon: mdiFileDocumentMultipleOutline,
    to: 'project-list',
    resource: 'public',
    action: 'read'
  },
  {
    title: 'Timelines',
    icon: mdiChartTimeline,
    children: [
      {
        title: 'User\'s Timeline',
        to: {name: 'user-timeline', params: { id: email }},
        icon: mdiAccountOutline,
        resource: 'timeline',
        action: 'read'
      },
      {
        title: 'Team\'s Timeline',
        to: {name: 'team-timeline', params: { id: favorite_teams_group }},
        icon: mdiMicrosoftTeams,
        resource: 'timeline',
        action: 'read'
      },
      {
        title: 'Office Timeline',
        to: {name: 'office-timeline', params: { id: office }},
        icon: mdiOfficeBuildingMarkerOutline,
        resource: 'timeline',
        action: 'read'
      },
      {
        title: 'Fields Timeline',
        to: {name: 'field-timeline'},
        icon: mdiMapMarkerMultipleOutline,
        resource: 'timeline',
        action: 'read'
      },
    ]
  }
]
